
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { createBrowserRouter, RouterProvider, Routes, Route, Link as RRDLink, Outlet } from 'react-router-dom'
import axios from 'axios'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import './styles.css'


import Immutable from 'immutable'
import { StateCreator } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

const stateCreator: StateCreator = (set, get) => {
  return {
    data: Immutable.Map(),
    setItem: (key, value) => {
      set((state) => {
        return {
          data: state.data.set(key, value),
        }
      })
    },
  }
}

const store = createWithEqualityFn()(subscribeWithSelector(stateCreator), shallow)

const getState = store.getState // Plain store without React
const subscribeState = store.subscribe
const useStore = store


const router = createBrowserRouter([{ path: '*', Component: Root }])

function useDisablePinch() {
  React.useEffect(() => {
    if (true) {
      return
    }

    const listener = (e: WheelEvent) => {
      const isPinch = e.ctrlKey
      if (isPinch) {
        e.preventDefault()
      }
    }

    document.addEventListener('wheel', listener, { passive: false })

    return () => {
      document.removeEventListener('wheel', listener)
    }
  }, [])
}


function Root() {
  useDisablePinch()
  
  const globalState = useStore((state) => state.data)

  React.useEffect(() => {
    
  }, [])

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        return (
          <div>
            Error rendering React:
            <br />
            {error.message}
          </div>
        )
      }}
    >
      <div className="relative min-h-screen">
      
      <div className="p-4 bg-green-300 text-blue-900 font-bold m-10 rounded-xl">
  July 4 main branch
</div>
      <div className="absolute w-fit h-fit bottom-0 right-0 p-6 sm:p-4 text-white">
  <a
    href="https://www.nodespell.com/?ref=6686bd4b8c2e35550db0da17"
    rel="noopener noreferrer"
    target="_blank"
  >
    <span className="font-normal text-[13px] pr-4 py-1 bg-[#6c4bf0] hover:brightness-[1.2] transition-all duration-200 rounded shadow-[inset_0_1.5px_3px_rgb(255,255,255,0.1),0_2px_6px_rgb(100,75,240,0.8)] flex items-center justify-center ">
      <img
        alt="Logo"
        className="w-6 h-6 mx-2 rounded"
        src="https://nodespell-test-bucket.s3.eu-west-2.amazonaws.com/assets/6570d6a5daef2766992ab753/clx7utx0h0a373b6ef4mc18vj"
      />
      Crafted in Nodespell
    </span>
  </a>
</div>
      </div>
    </ErrorBoundary>
  )
}

export default function App() {
  return <RouterProvider router={router} />
}
